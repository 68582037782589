const menu = document.querySelector(".menuWrapper .menu");
const menuWrapper = document.querySelector(".menuWrapper");
const MenuOff = document.getElementById("MenuOff");
const MenuOn = document.getElementById("MenuOn");
const subMenuButton = document.querySelector(".menu .menu-item-has-children a");
const subMenu = document.getElementById("subMenu");
const html = document.querySelector("html");
const subMenuChilds = document.querySelector(".menu .menu-item-has-children");
console.log(subMenuChilds);
const menuSub = subMenuChilds.querySelector(".sub-menu");
console.log(subMenu);
const thirdMenu = document.querySelectorAll(
  ".menu .menu-item-has-children .menu-item-has-children > a"
);
let currentOppendeThirdMenu;

window.thirdMenu = thirdMenu;

console.log(thirdMenu);

for (let i = 0; i <= thirdMenu.length; i++) {
  if (thirdMenu[i]) {
    thirdMenu[i].onclick = e => openOrCloseThirdMenu(thirdMenu[i], e);
  }
}

function openOrCloseThirdMenu(element, event) {
  event.preventDefault();
  const menu = element.parentElement.getElementsByClassName("sub-menu")[0];
  if (!menu.classList.contains("thirdMenuOpen")) {
    element.style.textDecoration = "underline";
    openThirdMenu(menu);
    currentOppendeThirdMenu = menu;
  } else {
    element.style.textDecoration = "initial";
    closeThirdMenu(menu);
    currentOppendeThirdMenu = menu;
  }
}

function openThirdMenu(element) {
  if (currentOppendeThirdMenu) {
    closeThirdMenu(currentOppendeThirdMenu);
  }
  element.classList.add("thirdMenuOpen");
}

function closeThirdMenu(element) {
  element.classList.remove("thirdMenuOpen");
}

function openSubMenu() {
  menuSub.classList.add("sub-menu-open");
  subMenu.classList.add("subMenuOpen");
}

function closeSubMenu() {
  menuSub.classList.remove("sub-menu-open");
  subMenu.classList.remove("subMenuOpen");
  closeThirdMenu(currentOppendeThirdMenu);
}

function openPopup() {
  window.scrollTo(0, 0);
  html.style.overflow = "hidden";
  menuWrapper.style.pointerEvents = "all";
  MenuOff.classList.add("MenuOffSwtiched");
  MenuOn.classList.add("MenuOnSwtiched");
  menu.classList.add("menuOpen");
}

function closePopup() {
  html.style.overflow = "initial";
  MenuOff.classList.remove("MenuOffSwtiched");
  MenuOn.classList.remove("MenuOnSwtiched");
  menu.classList.remove("menuOpen");
  menuWrapper.style.pointerEvents = "none";
  closeSubMenu();
  closeThirdMenu(currentOppendeThirdMenu);
}

MenuOn.onclick = () => closePopup();
MenuOff.onclick = () => openPopup();
subMenuButton.onclick = e => {
  e.preventDefault();
  if (!menuSub.classList.contains("sub-menu-open")) {
    openSubMenu();
    subMenuButton.style.textDecoration = "underline";
  } else {
    subMenuButton.style.textDecoration = "initial";
    closeSubMenu();
  }
};
